import { Backdrop } from 'ui/feedback/Backdrop';
import { ApiUnprocessableError } from 'api/errors';
import { useStore } from 'effector-react';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { showAlert, showErrorAlert } from 'ui/dialogs/Alert';
import { Step } from 'ui/edits/Step';
import { TextField } from 'ui/input/TextField';
import { DateMask } from 'ui/masks';
import { dateValidator } from 'utils/validators';
import { $step4, documentSignFx, nextStep, prevStep, step4Set } from '../../model';
import cl from './Step4.module.scss';

interface ErrorsType {
  applicationNumber: undefined | string;
  applicationDate: undefined | string;
  dop_period: undefined | string
}
interface Props {
  docType?: number
}
const Step4:React.FC<Props> = ({docType}) => {
  const [isFetching, setFetching] = useState(false);

  const [errors, setErrors] = useState<ErrorsType>({
    applicationNumber: undefined,
    applicationDate: undefined,
    dop_period: undefined,
  });
  const step4 = useStore($step4);

  const handleNext = useCallback(async () => {
    if (dateValidator(step4.applicationDate, true, true) || (dateValidator(step4.dop_period, true, true) && docType === 6)) {
      if (dateValidator(step4.dop_period, true, true) && docType === 6){
        setErrors((state) => ({ ...state, dop_period: dateValidator(step4.dop_period, true, true) }));
      } else {
        setErrors((state) => ({ ...state, applicationDate: dateValidator(step4.applicationDate, true, true) }));
      }
    } else {
      try {
        setFetching(true);
        await documentSignFx();
        setFetching(false);
        nextStep();
      } catch (e) {
        setFetching(false);
        if (e instanceof ApiUnprocessableError) await showErrorAlert();
        else
          await showAlert(
            'error',
            'Связь с сервером была потеряна. Проверьте подключение к интернету и повторите снова.',
            'Ошибка',
          );
      }
    }
  }, [step4.applicationDate, step4.dop_period, docType]);
  const handlePrevStep = useCallback(() => {
    prevStep();
  }, []);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    step4Set({ ...step4, [name]: value });
  };
  const isDisabledNextExtra = docType === 6 && !step4.dop_period;
  return (
    <Step
      title={docType === 6 ? 'Номер, дата договора. Дата начала занятий' :'Номер и дата договора'}
      disabledNext={!step4.applicationDate || !step4.applicationNumber || isDisabledNextExtra}
      onClickNext={handleNext}
      prevStep={handlePrevStep}
    >
      <Backdrop open={isFetching} />
      <>
        <div className={cl.container}>
          <TextField
            label="Введите номер договора"
            fullWidth
            name="applicationNumber"
            value={step4.applicationNumber}
            onChange={onChange}
            error={!!errors.applicationNumber}
            helperText={errors.applicationNumber}
          />
          <TextField
            label="Введите дату договора"
            fullWidth
            name="applicationDate"
            InputProps={{ inputComponent: DateMask }}
            value={step4.applicationDate}
            onChange={onChange}
            error={!!errors.applicationDate}
            helperText={errors.applicationDate}
          />
          {docType === 6 && (
            <TextField
            label="Введите дату фактического начала занятий"
            fullWidth
            name="dop_period"
            InputProps={{ inputComponent: DateMask }}
            value={step4.dop_period}
            onChange={onChange}
            error={!!errors.dop_period}
            helperText={errors.dop_period}
          />
          )}
        </div>
      </>
    </Step>
  );
};

export default Step4;
