import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Button } from 'ui/button/Button';
import Agreement from 'ui/dialogs/Agreement';
import { AddressPanel, ContactPanel, PhotosPanel } from 'ui/panels';
import { PanelMini } from 'ui/panels/PanelMini';
import cl from './Garten.module.scss';
import { useGartens } from 'hooks/gartens';
import { IconButton } from '@material-ui/core';
import Icon from 'ui/Icon';
import { useProfile } from 'hooks/profile';
import { EmailConfirm } from 'ui/dialogs/EmailConfirm/EmailConfirm';
import { ConfirmDialog } from 'routes/main/routes/profile/routes/email/EmailPanel/ConfirmDialog';

interface Props extends RouteComponentProps<{ id: string }> {}

const Garten: React.FC<Props> = ({
  match,
  match: {
    params: { id },
  },
}) => {
  const history = useHistory();

  const { data: gartensData } = useGartens();

  const currentGarten = gartensData.find((elem) => elem.id === +id);

  //подтверждение соглашений

  const [isOpenAgreement, setOpenAgreement] = useState(false);

  const handleClickBack = useCallback(() => {
    history.push('/gartens');
  }, [history]);

  const handleClickRouteToJoin = useCallback(() => {
    history.push(`/gartens/${+id}/join`);
  }, [history, id]);

  const { data: profile } = useProfile();

  const [isOpenEmailCreate, setOpenEmailCreate] = useState(false);

  const handleCloseEmailConfirmDialog = useCallback(() => {
    setOpenEmailCreate(false);
  }, []);

  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(profile.email || '');
  }, [profile.email]);

  //почта не подтверждена
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleDialogOk = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  //навигация по dialogs
  const handleSignUp = useCallback(() => {
    if (profile.email === null) {
      setOpenEmailCreate(true);
    } else if (!profile.email_verified) {
      setOpenConfirm(true);
    } else {
      setOpenAgreement(true);
    }
  }, [profile.email, profile.email_verified]);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <div className={cl.title}>
          <IconButton className={cl.iconBack} onClick={handleClickBack}>
            <Icon type="Back" />
          </IconButton>
          <h1>Подача документов</h1>
        </div>
        <Button className={cl.desctopBtn} onClick={handleSignUp}>
          Продолжить
        </Button>
      </div>
      <div className={cl.agree}>
        Для подтверждения сведений необходимо предоставить оригиналы документов по данному адресу
      </div>
      <ContactPanel data={currentGarten?.place.contacts || []} title="Отдел зачисления" />
      <div className={cl.descr}>
        <PanelMini title="Режим работы">{currentGarten?.place.work_time}</PanelMini>
      </div>
      {!!currentGarten?.place.photos.length && <PhotosPanel data={currentGarten?.place.photos} />}
      {currentGarten?.place.address && <AddressPanel address={currentGarten.place.address} />}
      <Button className={cl.mobileBtn} onClick={handleSignUp}>
        Продолжить
      </Button>

      <EmailConfirm
        isOpen={isOpenEmailCreate}
        handleCloseEmailConfirmDialog={handleCloseEmailConfirmDialog}
        setOpenConfirm={setOpenConfirm}
        email={email}
        setEmail={setEmail}
      />

      <ConfirmDialog open={openConfirm} email={email} onClickOk={handleDialogOk} />

      <Agreement
        type="gartens"
        isOpenAgreement={isOpenAgreement}
        setOpenAgreement={setOpenAgreement}
        onClickContinue={handleClickRouteToJoin}
      />
    </div>
  );
};

export default Garten;
