import { useStore } from 'effector-react';
import { useDocuments } from 'hooks/documents';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'ui/button/Button';
import { ChildAdd } from 'ui/edits/ChildAdd';
import { EmailEdit } from 'ui/edits/EmailEdit';
import { ProfileEdit } from 'ui/edits/ProfileEdit';
import { RequisitesEdit } from 'ui/edits/RequisitesEdit';
import { SecondRepresentative } from 'ui/edits/SecondRepresentativeEdit';
import { Step, Stepper } from 'ui/stepper/Stepper';
import { Step1 } from '../../../../../../ui/commonSteps/Step1/Step1';
import { Step2 } from '../../../../../../ui/commonSteps/Step2/Step2';
import cl from './DocumentSign.module.scss';
import {
  $editChild,
  $step,
  $step1,
  $step1Rep,
  $step2,
  $viewType,
  addChild,
  changeViewType,
  initChild,
  nextStep,
  prevStep,
  resetSteps,
  setCurrentId,
  setEditChild,
  step1SetProfile,
  step1SetProfileRep,
  step1ToggleSaver,
  step2SetChild,
  step2ToggleSaver,
  updateChild,
} from './model';
import { Step3 } from './steps/Step3/Step3';
import Step4 from './steps/Step4/Step4';
import { Step5 } from './steps/Step5/Step5';

interface Props extends RouteComponentProps<{ id: string }> {}

const DocumentSignDs: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const step = useStore($step);

  const profileInfo = useStore($step1);

  const profileRepInfo = useStore($step1Rep);

  const viewType = useStore($viewType);

  const history = useHistory();

  const child = useStore($editChild);

  const { needSave, profile } = useStore($step1);

  const documents = useDocuments();

  const { profileRep } = useStore($step1Rep);

  const children = useStore($step2);

  const stepRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (stepRef.current) {
      const { top } = stepRef.current.getBoundingClientRect();
      setOffset(top + 46);
    }
  }, [stepRef]);

  useEffect(() => {
    setCurrentId(+id);
  }, [id]);
  const STEPS: Step[] = (documents.data.find(el => el.id === +id)?.type === 3 || documents.data.find(el => el.id === +id)?.type === 4 || documents.data.find(el => el.id === +id)?.type === 6)  ? [
    {
      label: 'Реквизиты',
      component: (
        <Step1
          needSave={needSave}
          profile={profile}
          profileRep={profileRep}
          changeViewType={changeViewType}
          nextStep={nextStep}
          step1SetProfile={step1SetProfile}
          step1ToggleSaver={step1ToggleSaver}
          isActualAddressNeeded={false}
        />
      ),
    },
    {
      label: 'Выбор ребенка',
      component: (
        <Step2
          childrenInfo={children}
          changeViewType={changeViewType}
          initChild={initChild}
          nextStep={nextStep}
          setEditChild={setEditChild}
          step2ToggleSaver={step2ToggleSaver}
          step2SetChild={step2SetChild}
          prevStep={prevStep}
          type={'school'}
        />
      ),
    },
    {
      label: 'Номер и дата договора',
      component: <Step4 docType={documents.data.find(el => el.id === +id)?.type} />,
    },
    {
      label: 'Подписание договора',
      component: <Step5 />,
    },
  ] : [
    {
      label: 'Реквизиты',
      component: (
        <Step1
          needSave={needSave}
          profile={profile}
          profileRep={profileRep}
          changeViewType={changeViewType}
          nextStep={nextStep}
          step1SetProfile={step1SetProfile}
          step1ToggleSaver={step1ToggleSaver}
          isActualAddressNeeded={false}
        />
      ),
    },
    {
      label: 'Выбор ребенка',
      component: (
        <Step2
          childrenInfo={children}
          changeViewType={changeViewType}
          initChild={initChild}
          nextStep={nextStep}
          setEditChild={setEditChild}
          step2ToggleSaver={step2ToggleSaver}
          step2SetChild={step2SetChild}
          prevStep={prevStep}
          type={'school'}
        />
      ),
    },
    {
      label: 'Льготы',
      component: <Step3 />,
    },
    {
      label: 'Номер и дата договора',
      component: <Step4 />,
    },
    {
      label: 'Подписание договора',
      component: <Step5 />,
    },
  ];
  const location = useLocation();
  const pathInfo = location.pathname.split('/')[2];

  const handleClickCancel = useCallback(() => {
    resetSteps()
    history.push(`/documents/${pathInfo}/${id}`);
  }, [history, id, pathInfo]);

  return (
    <div className={cl.root}>
      <div className={cl.header}>
        <h1>Подписание документа</h1>
        <Button variant="text" onClick={handleClickCancel}>
          Отменить запись
        </Button>
      </div>
      <Stepper steps={STEPS} current={step} />
      {viewType === 'step' && (
        <div ref={stepRef} style={{ minHeight: `calc(100vh - ${offset}px)` }} className={cl.stepWrapper}>
          {STEPS[step].component}
        </div>
      )}
      {viewType === 'profile' && (
        <ProfileEdit state={profileInfo} changeViewType={changeViewType} stepSetProfile={step1SetProfile} />
      )}
      {viewType === 'email' && <EmailEdit state={profileInfo} changeViewType={changeViewType} />}
      {viewType === 'requisites' && (
        <RequisitesEdit state={profileInfo} changeViewType={changeViewType} stepSetProfile={step1SetProfile} />
      )}
      {viewType === 'secondRepresentative' && (
        <SecondRepresentative
          state={profileRepInfo}
          changeViewType={changeViewType}
          stepSetProfile={step1SetProfileRep}
        />
      )}
      {viewType === 'child' && (
        <ChildAdd
          childInfo={child}
          addChild={addChild}
          setEditChild={setEditChild}
          updateChild={updateChild}
          changeViewType={changeViewType}
          isNeededExtraFields={true}
        />
      )}
    </div>
  );
};

export default DocumentSignDs;
